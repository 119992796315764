export default class Animation {
    constructor() {

    }

    animateImageHove() {
        // Init
        var container = document.getElementById("hero__image__animation"),
            inner = document.getElementById("image_animation");

        // Mouse
        var mouse = {
            _x: 0,
            _y: 0,
            x: 0,
            y: 0,
            updatePosition: function (event) {
                var e = event || window.event;
                this.x = e.clientX - this._x;
                this.y = (e.clientY - this._y) * -1;
            },
            setOrigin: function (e) {
                this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
                this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
            },
            show: function () {
                return "(" + this.x + ", " + this.y + ")";
            }
        };

        // Track the mouse position relative to the center of the container.
        mouse.setOrigin(container);

        //-----------------------------------------

        var counter = 0;
        var updateRate = 10;
        var isTimeToUpdate = function () {
            return counter++ % updateRate === 0;
        };

        //-----------------------------------------

        var onMouseEnterHandler = function (event) {
            update(event);
        };

        var onMouseLeaveHandler = function () {
            inner.style = "";
        };

        var onMouseMoveHandler = function (event) {
            if (isTimeToUpdate()) {
                update(event);
            }
        };

        //-----------------------------------------

        var update = function (event) {
            mouse.updatePosition(event);
            updateTransformStyle(
                (mouse.y / inner.offsetHeight / 2).toFixed(2),
                (mouse.x / inner.offsetWidth / 2).toFixed(2)
            );
        };

        var updateTransformStyle = function (x, y) {
            var style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
            inner.style.transform = style;
            inner.style.webkitTransform = style;
            inner.style.mozTransform = style;
            inner.style.msTransform = style;
            inner.style.oTransform = style;
        };

        //-----------------------------------------

        container.onmouseenter = onMouseEnterHandler;
        container.onmouseleave = onMouseLeaveHandler;
        container.onmousemove = onMouseMoveHandler;
    
    }

    textAnimation() 
    {
        var texts = document.getElementsByClassName('textAnimation');
        console.log(texts);
        
        var animationDelay = 6;

        for (let text of texts) {
            var newDom = '';
            for(let i = 0; i < text.innerText.length; i++)
            {
                newDom += '<span class="char">' + (text.innerText[i] == ' ' ? '&nbsp;' : text.innerText[i])+ '</span>';
            }
    
            text.innerHTML = newDom;
            var length = text.children.length;
    
            for(let i = 0; i < length; i++)
            {
                text.children[i].style['animation-delay'] = animationDelay * i + 'ms';
            }
        }
    }

    paragraphAnimation() {
        var paragraphes = document.getElementsByClassName('paragraphe');

        for (let paragraphe of paragraphes) {
            paragraphe.style.opacity = 1;
        }
    }
}