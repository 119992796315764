// here you put all the js you want.
import Animation from "./js/animation.js";

const animation = new Animation()

animation.animateImageHove()

animation.textAnimation()

animation.paragraphAnimation()


// $ = jQuery.noConflict();

// $(document).ready(function() {
//     "use strict";
    
//     $('.price_slider_wrapper').parents('.widget').addClass('widget_price_filter');
//     console.log('before initSelect2')
//     initSelect2();
//     initSingleProductLightbox();
//     initAddToCartPlusMinus()
// });
    
// function initSelect2() {
// $ = jQuery.noConflict();
//     "use strict";

//     console.log('initSelect2')
    
//     $('.woocommerce-ordering .orderby, #calc_shipping_country, #dropdown_product_cat').select2({
//         minimumResultsForSearch: -1
//     });

//     $('.woocommerce-account .country_select').select2();
// }